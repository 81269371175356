<template>
 <div class="memberRechargePay-view">
   <img src="../assets/images/tengxunsp_p.png" alt="">

   <div class="memberRechargePay-title">
     <label><b>腾讯视频年会员</b></label>
     <div class="memberRechargePay-info">
       <span>￥188.00</span>
       <del>原价288.00</del>
       <span>销量:499</span>
     </div>
   </div>

   <van-cell-group>
     <van-field v-model="value" label="充值账号" placeholder="*仅限填入充值账号" input-align="right"/>
     <van-field v-model="value" label="备注" placeholder="请填写备注留意" input-align="right"/>
   </van-cell-group>

   <div class="recharge-btn">
     <nut-row>
       <nut-col :span="16">
         <div class="flex-content payment">
           应付{{money||0}}元
         </div>
       </nut-col>
       <nut-col :span="8" v-on:click="pay()">
         <div class="flex-content flex-content-light flex-content-btn">
           支付
         </div>
       </nut-col>
     </nut-row>
   </div>

 </div>
</template>

<script>
export default {
  name: "MemberRechargePay"
}
</script>

<style scoped>
.memberRechargePay-view img{
  width: 100%;
}
.memberRechargePay-title{
  padding: 5px 10px;
  background: #FFFFFF;
}
.memberRechargePay-title label{
  font-size: 20px;
}
.memberRechargePay-info{
  margin: 8px 0;
}
.memberRechargePay-info del{
  margin-left: 15px;
  color: #cccccc;
}
.memberRechargePay-info span:first-child{
  color: #F40;
  font-size: 20px;
}
.memberRechargePay-info span:last-child{
  float: right;
  color: #a5a5a5;
  margin-top: 3px;
}
.recharge-btn{
  background: #ffffff;
  border-top: 1px solid #cccccc;
  height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.payment{
  margin-left: 20px;
  color: #ff2a2a;
  font-size: 18px;
}
.flex-content-btn{
  background: #2BD67E;
  text-align: center;
  color: #ffffff;
}
.active{
  background: #c9ffd2;
}
</style>
